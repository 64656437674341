import Axios from 'axios';
import { all, fork, takeEvery } from 'redux-saga/effects';
import {
	MEMBER_GROUPS_CREATE,
	MEMBER_GROUPS_UPDATE,
	MEMBER_GROUPS_FIND_ALL_BY_PAGE_AND_OPTIONS,
	MEMBER_GROUPS_GET_DETAILS,
} from 'Constants/actionTypes';
import { apiUrl } from 'Constants/defaultValues';
import { defaultFetchDataFunc } from 'Util/Saga';
import {
	memberGroupsCreateSuccess,
	memberGroupsCreateFail,
	memberGroupsUpdateSuccess,
	memberGroupsUpdateFail,
	memberGroupsFindAllByPageAndOptionsSuccess,
	memberGroupsFindAllByPageAndOptionsFail,
	memberGroupsGetDetailsSuccess,
	memberGroupsGetDetailsFail,
} from './actions';

export function* watchMemberGroupsCreate() {
	yield takeEvery(MEMBER_GROUPS_CREATE, defaultFetchDataFunc(
		async (data) => {
			const response = await Axios.post(`${apiUrl}/memberGroup/create`, data);
			return response.data;
		},
		memberGroupsCreateSuccess,
		memberGroupsCreateFail
	));
}

export function* watchMemberGroupsUpdate() {
	yield takeEvery(MEMBER_GROUPS_UPDATE, defaultFetchDataFunc(
		async (data) => {
			const response = await Axios.put(`${apiUrl}/memberGroup/update`, data);
			return response.data;
		},
		memberGroupsUpdateSuccess,
		memberGroupsUpdateFail
	));
}

export function* watchMemberGroupsFindAllByPageAndOptions() {
	yield takeEvery(MEMBER_GROUPS_FIND_ALL_BY_PAGE_AND_OPTIONS, defaultFetchDataFunc(
		async (data) => {
			const response = await Axios.get(`${apiUrl}/memberGroup/findAllByPageAndOptions`, { params: data });
			return response.data;
		},
		memberGroupsFindAllByPageAndOptionsSuccess,
		memberGroupsFindAllByPageAndOptionsFail
	));
}

export function* watchMemberGroupsGetDetails() {
	yield takeEvery(MEMBER_GROUPS_GET_DETAILS, defaultFetchDataFunc(
		async (data) => {
			const response = await Axios.get(`${apiUrl}/memberGroup/details`, { params: { id: data } });
			return response.data;
		},
		memberGroupsGetDetailsSuccess,
		memberGroupsGetDetailsFail
	));
}

export default function* rootSaga() {
	yield all([
		fork(watchMemberGroupsCreate),
		fork(watchMemberGroupsUpdate),
		fork(watchMemberGroupsFindAllByPageAndOptions),
		fork(watchMemberGroupsGetDetails),
	]);
}
