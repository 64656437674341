import { combineReducers } from 'redux';
import { Map } from 'immutable';
import { createStructuredSelector } from 'reselect';

import {
	MEMBER_GROUPS_CREATE,
	MEMBER_GROUPS_CREATE_SUCCESS,
	MEMBER_GROUPS_CREATE_FAIL,
	MEMBER_GROUPS_UPDATE,
	MEMBER_GROUPS_UPDATE_SUCCESS,
	MEMBER_GROUPS_UPDATE_FAIL,
	MEMBER_GROUPS_FIND_ALL_BY_PAGE_AND_OPTIONS,
	MEMBER_GROUPS_FIND_ALL_BY_PAGE_AND_OPTIONS_SUCCESS,
	MEMBER_GROUPS_FIND_ALL_BY_PAGE_AND_OPTIONS_FAIL,
	MEMBER_GROUPS_GET_DETAILS,
	MEMBER_GROUPS_GET_DETAILS_SUCCESS,
	MEMBER_GROUPS_GET_DETAILS_FAIL,
	OPTIONS_GET,
	OPTIONS_GET_SUCCESS,
	OPTIONS_GET_FAIL,
} from 'Constants/actionTypes';

const statusReducer = (state = new Map(), action) => {
	switch (action.type) {
	case MEMBER_GROUPS_CREATE:
	case MEMBER_GROUPS_UPDATE:
		return state.set('memberGroup', 'submitting');
	case MEMBER_GROUPS_CREATE_SUCCESS:
	case MEMBER_GROUPS_UPDATE_SUCCESS:
		return state.set('memberGroup', 'submitted');
	case MEMBER_GROUPS_FIND_ALL_BY_PAGE_AND_OPTIONS:
		return state.set('memberGroups', 'fetching');
	case MEMBER_GROUPS_FIND_ALL_BY_PAGE_AND_OPTIONS_SUCCESS:
		return state.set('memberGroups', 'fetched');
	case MEMBER_GROUPS_FIND_ALL_BY_PAGE_AND_OPTIONS_FAIL:
		return state.set('memberGroups', 'has-errors');
	case MEMBER_GROUPS_GET_DETAILS:
		return state.set('memberGroup', 'fetching');
	case MEMBER_GROUPS_GET_DETAILS_SUCCESS:
		return state.set('memberGroup', 'fetched');
	case MEMBER_GROUPS_CREATE_FAIL:
	case MEMBER_GROUPS_UPDATE_FAIL:
	case MEMBER_GROUPS_GET_DETAILS_FAIL:
		return state.set('memberGroup', 'has-errors');
	case OPTIONS_GET:
		return action.payload.tag === 'memberGroup' ? state.set('options', 'fetching') : state;
	case OPTIONS_GET_SUCCESS:
		return action.payload.tag === 'memberGroup' ? state.set('options', 'fetched') : state;
	case OPTIONS_GET_FAIL:
		return action.payload.tag === 'memberGroup' ? state.set('options', 'has-errors') : state;
	default:
		return state;
	}
};

const currentReducer = (state = null, action) => {
	switch (action.type) {
	case MEMBER_GROUPS_CREATE:
	case MEMBER_GROUPS_FIND_ALL_BY_PAGE_AND_OPTIONS:
		return null;
	case MEMBER_GROUPS_CREATE_SUCCESS:
	case MEMBER_GROUPS_UPDATE_SUCCESS:
	case MEMBER_GROUPS_GET_DETAILS_SUCCESS:
		return action.payload.id;
	default:
		return state;
	}
};

const memberGroupsByPageReducer = (state = new Map(), action) => {
	switch (action.type) {
	case MEMBER_GROUPS_FIND_ALL_BY_PAGE_AND_OPTIONS:
		return state;
	case MEMBER_GROUPS_FIND_ALL_BY_PAGE_AND_OPTIONS_SUCCESS:
		return new Map(action.payload);
	default:
		return state;
	}
};

const memberGroupReducer = (state = new Map(), action) => {
	switch (action.type) {
	case MEMBER_GROUPS_GET_DETAILS:
		return new Map();
	case MEMBER_GROUPS_CREATE_SUCCESS:
	case MEMBER_GROUPS_UPDATE_SUCCESS:
	case MEMBER_GROUPS_GET_DETAILS_SUCCESS:
		return state.set(action.payload.id, action.payload);
	default:
		return state;
	}
};

const optionsReducer = (state = new Map(), action) => {
	switch (action.type) {
	case OPTIONS_GET:
		return action.payload.tag === 'memberGroup' ? new Map() : state;
	case OPTIONS_GET_SUCCESS:
		return action.payload.tag === 'memberGroup' ? new Map(action.payload.options) : state;
	default:
		return state;
	}
};

export default combineReducers({
	status: statusReducer,
	current: currentReducer,
	memberGroupsByPage: memberGroupsByPageReducer,
	memberGroup: memberGroupReducer,
	options: optionsReducer,
});

export const selector = createStructuredSelector({
	status: (state) => state.memberGroups.status,
	current: (state) => state.memberGroups.current,
	memberGroupsByPage: (state) => state.memberGroups.memberGroupsByPage,
	memberGroup: (state) => state.memberGroups.memberGroup,
	options: (state) => state.memberGroups.options,
	errors: (state) => state.errors.get('memberGroup'),
});
