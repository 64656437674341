/* Settings */
export const CHANGE_LOCALE = 'CHANGE_LOCALE';

/* Menu */
export const MENU_SET_CLASSNAMES = 'MENU_SET_CLASSNAMES';
export const MENU_CONTAINER_ADD_CLASSNAME = 'MENU_CONTAINER_ADD_CLASSNAME';
export const MENU_CLICK_MOBILE_MENU = 'MENU_CLICK_MOBILE_MENU';
export const MENU_CHANGE_DEFAULT_CLASSES = 'MENU_CHANGE_DEFAULT_CLASSES';
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS = 'MENU_CHANGE_HAS_SUB_ITEM_STATUS';

/* Auth */
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAIL = 'LOGIN_USER_FAIL';

export const REFRESH_ACCESS_TOKEN = 'REFRESH_ACCESS_TOKEN';
export const REFRESH_ACCESS_TOKEN_SUCCESS = 'REFRESH_ACCESS_TOKEN_SUCCESS';
export const REFRESH_ACCESS_TOKEN_FAIL = 'REFRESH_ACCESS_TOKEN_FAIL';

export const LOGOUT_USER = 'LOGOUT_USER';

/* Error */
export const ERROR_RESET = 'ERROR_RESET';

/* Options */
export const OPTIONS_GET = 'OPTIONS_GET';
export const OPTIONS_GET_SUCCESS = 'OPTIONS_GET_SUCCESS';
export const OPTIONS_GET_FAIL = 'OPTIONS_GET_FAIL';

/* User */
export const USERS_READ_CURRENT = 'USERS_READ_CURRENT';
export const USERS_READ_CURRENT_SUCCESS = 'USERS_READ_CURRENT_SUCCESS';
export const USERS_READ_CURRENT_FAIL = 'USERS_READ_CURRENT_FAIL';

export const USERS_ASSIGN = 'USERS_ASSIGN';
export const USERS_ASSIGN_SUCCESS = 'USERS_ASSIGN_SUCCESS';
export const USERS_ASSIGN_FAIL = 'USERS_ASSIGN_FAIL';

export const USERS_UNLOCK = 'USERS_UNLOCK';
export const USERS_UNLOCK_SUCCESS = 'USERS_UNLOCK_SUCCESS';
export const USERS_UNLOCK_FAIL = 'USERS_UNLOCK_FAIL';

export const USERS_CLEAR_2FA_SECRET = 'USERS_CLEAR_2FA_SECRET';
export const USERS_CLEAR_2FA_SECRET_SUCCESS = 'USERS_CLEAR_2FA_SECRET_SUCCESS';
export const USERS_CLEAR_2FA_SECRET_FAIL = 'USERS_CLEAR_2FA_SECRET_FAIL';

export const USERS_RESET_2FA_SECRET = 'USERS_RESET_2FA_SECRET';
export const USERS_RESET_2FA_SECRET_SUCCESS = 'USERS_RESET_2FA_SECRET_SUCCESS';
export const USERS_RESET_2FA_SECRET_FAIL = 'USERS_RESET_2FA_SECRET_FAIL';

export const USERS_CHANGE_PASSWORD = 'USERS_CHANGE_PASSWORD';
export const USERS_CHANGE_PASSWORD_SUCCESS = 'USERS_CHANGE_PASSWORD_SUCCESS';
export const USERS_CHANGE_PASSWORD_FAIL = 'USERS_CHANGE_PASSWORD_FAIL';

export const USERS_FIND_ALL_BY_PAGE_AND_OPTIONS = 'USERS_FIND_ALL_BY_PAGE_AND_OPTIONS';
export const USERS_FIND_ALL_BY_PAGE_AND_OPTIONS_SUCCESS = 'USERS_FIND_ALL_BY_PAGE_AND_OPTIONS_SUCCESS';
export const USERS_FIND_ALL_BY_PAGE_AND_OPTIONS_FAIL = 'USERS_FIND_ALL_BY_PAGE_AND_OPTIONS_FAIL';

export const USERS_GET_DETAILS = 'USERS_GET_DETAILS';
export const USERS_GET_DETAILS_SUCCESS = 'USERS_GET_DETAILS_SUCCESS';
export const USERS_GET_DETAILS_FAIL = 'USERS_GET_DETAILS_FAIL';

export const USERS_GET_PERMISSIONS = 'USERS_GET_PERMISSIONS';
export const USERS_GET_PERMISSIONS_SUCCESS = 'USERS_GET_PERMISSIONS_SUCCESS';
export const USERS_GET_PERMISSIONS_FAIL = 'USERS_GET_PERMISSIONS_FAIL';

/* User Role */
export const USER_ROLES_CREATE = 'USER_ROLES_CREATE';
export const USER_ROLES_CREATE_SUCCESS = 'USER_ROLES_CREATE_SUCCESS';
export const USER_ROLES_CREATE_FAIL = 'USER_ROLES_CREATE_FAIL';

export const USER_ROLES_UPDATE = 'USER_ROLES_UPDATE';
export const USER_ROLES_UPDATE_SUCCESS = 'USER_ROLES_UPDATE_SUCCESS';
export const USER_ROLES_UPDATE_FAIL = 'USER_ROLES_UPDATE_FAIL';

export const USER_ROLES_FIND_ALL_BY_PAGE_AND_OPTIONS = 'USER_ROLES_FIND_ALL_BY_PAGE_AND_OPTIONS';
export const USER_ROLES_FIND_ALL_BY_PAGE_AND_OPTIONS_SUCCESS = 'USER_ROLES_FIND_ALL_BY_PAGE_AND_OPTIONS_SUCCESS';
export const USER_ROLES_FIND_ALL_BY_PAGE_AND_OPTIONS_FAIL = 'USER_ROLES_FIND_ALL_BY_PAGE_AND_OPTIONS_FAIL';

export const USER_ROLES_GET_DETAILS = 'USER_ROLES_GET_DETAILS';
export const USER_ROLES_GET_DETAILS_SUCCESS = 'USER_ROLES_GET_DETAILS_SUCCESS';
export const USER_ROLES_GET_DETAILS_FAIL = 'USER_ROLES_GET_DETAILS_FAIL';

/* Company */
export const COMPANIES_CREATE = 'COMPANIES_CREATE';
export const COMPANIES_CREATE_SUCCESS = 'COMPANIES_CREATE_SUCCESS';
export const COMPANIES_CREATE_FAIL = 'COMPANIES_CREATE_FAIL';

export const COMPANIES_UPDATE = 'COMPANIES_UPDATE';
export const COMPANIES_UPDATE_SUCCESS = 'COMPANIES_UPDATE_SUCCESS';
export const COMPANIES_UPDATE_FAIL = 'COMPANIES_UPDATE_FAIL';

export const COMPANIES_FIND_ALL_BY_PAGE_AND_OPTIONS = 'COMPANIES_FIND_ALL_BY_PAGE_AND_OPTIONS';
export const COMPANIES_FIND_ALL_BY_PAGE_AND_OPTIONS_SUCCESS = 'COMPANIES_FIND_ALL_BY_PAGE_AND_OPTIONS_SUCCESS';
export const COMPANIES_FIND_ALL_BY_PAGE_AND_OPTIONS_FAIL = 'COMPANIES_FIND_ALL_BY_PAGE_AND_OPTIONS_FAIL';

export const COMPANIES_GET_DETAILS = 'COMPANIES_GET_DETAILS';
export const COMPANIES_GET_DETAILS_SUCCESS = 'COMPANIES_GET_DETAILS_SUCCESS';
export const COMPANIES_GET_DETAILS_FAIL = 'COMPANIES_GET_DETAILS_FAIL';

/* Warehouses */
export const WAREHOUSES_UPDATE = 'WAREHOUSES_UPDATE';
export const WAREHOUSES_UPDATE_SUCCESS = 'WAREHOUSES_UPDATE_SUCCESS';
export const WAREHOUSES_UPDATE_FAIL = 'WAREHOUSES_UPDATE_FAIL';

export const WAREHOUSES_FIND_ALL_BY_PAGE_AND_OPTIONS = 'WAREHOUSES_FIND_ALL_BY_PAGE_AND_OPTIONS';
export const WAREHOUSES_FIND_ALL_BY_PAGE_AND_OPTIONS_SUCCESS = 'WAREHOUSES_FIND_ALL_BY_PAGE_AND_OPTIONS_SUCCESS';
export const WAREHOUSES_FIND_ALL_BY_PAGE_AND_OPTIONS_FAIL = 'WAREHOUSES_FIND_ALL_BY_PAGE_AND_OPTIONS_FAIL';

export const WAREHOUSES_GET_DETAILS = 'WAREHOUSES_GET_DETAILS';
export const WAREHOUSES_GET_DETAILS_SUCCESS = 'WAREHOUSES_GET_DETAILS_SUCCESS';
export const WAREHOUSES_GET_DETAILS_FAIL = 'WAREHOUSES_GET_DETAILS_FAIL';

export const WAREHOUSES_GET_IP_WHITELISTS = 'WAREHOUSES_GET_IP_WHITELISTS';
export const WAREHOUSES_GET_IP_WHITELISTS_SUCCESS = 'WAREHOUSES_GET_IP_WHITELISTS_SUCCESS';
export const WAREHOUSES_GET_IP_WHITELISTS_FAIL = 'WAREHOUSES_GET_IP_WHITELISTS_FAIL';

export const WAREHOUSES_BATCH_UPDATE_IP_WHITELISTS = 'WAREHOUSES_BATCH_UPDATE_IP_WHITELISTS';
export const WAREHOUSES_BATCH_UPDATE_IP_WHITELISTS_SUCCESS = 'WAREHOUSES_BATCH_UPDATE_IP_WHITELISTS_SUCCESS';
export const WAREHOUSES_BATCH_UPDATE_IP_WHITELISTS_FAIL = 'WAREHOUSES_BATCH_UPDATE_IP_WHITELISTS_FAIL';

/* Warehouse Groups */
export const WAREHOUSE_GROUPS_CREATE = 'WAREHOUSE_GROUPS_CREATE';
export const WAREHOUSE_GROUPS_CREATE_SUCCESS = 'WAREHOUSE_GROUPS_CREATE_SUCCESS';
export const WAREHOUSE_GROUPS_CREATE_FAIL = 'WAREHOUSE_GROUPS_CREATE_FAIL';

export const WAREHOUSE_GROUPS_UPDATE = 'WAREHOUSE_GROUPS_UPDATE';
export const WAREHOUSE_GROUPS_UPDATE_SUCCESS = 'WAREHOUSE_GROUPS_UPDATE_SUCCESS';
export const WAREHOUSE_GROUPS_UPDATE_FAIL = 'WAREHOUSE_GROUPS_UPDATE_FAIL';

export const WAREHOUSE_GROUPS_FIND_ALL_BY_PAGE_AND_OPTIONS = 'WAREHOUSE_GROUPS_FIND_ALL_BY_PAGE_AND_OPTIONS';
export const WAREHOUSE_GROUPS_FIND_ALL_BY_PAGE_AND_OPTIONS_SUCCESS = 'WAREHOUSE_GROUPS_FIND_ALL_BY_PAGE_AND_OPTIONS_SUCCESS';
export const WAREHOUSE_GROUPS_FIND_ALL_BY_PAGE_AND_OPTIONS_FAIL = 'WAREHOUSE_GROUPS_FIND_ALL_BY_PAGE_AND_OPTIONS_FAIL';

export const WAREHOUSE_GROUPS_GET_DETAILS = 'WAREHOUSE_GROUPS_GET_DETAILS';
export const WAREHOUSE_GROUPS_GET_DETAILS_SUCCESS = 'WAREHOUSE_GROUPS_GET_DETAILS_SUCCESS';
export const WAREHOUSE_GROUPS_GET_DETAILS_FAIL = 'WAREHOUSE_GROUPS_GET_DETAILS_FAIL';

/* Payment Methods */
export const PAYMENT_METHODS_UPDATE = 'PAYMENT_METHODS_UPDATE';
export const PAYMENT_METHODS_UPDATE_SUCCESS = 'PAYMENT_METHODS_UPDATE_SUCCESS';
export const PAYMENT_METHODS_UPDATE_FAIL = 'PAYMENT_METHODS_UPDATE_FAIL';

export const PAYMENT_METHODS_FIND_ALL = 'PAYMENT_METHODS_FIND_ALL';
export const PAYMENT_METHODS_FIND_ALL_SUCCESS = 'PAYMENT_METHODS_FIND_ALL_SUCCESS';
export const PAYMENT_METHODS_FIND_ALL_FAIL = 'PAYMENT_METHODS_FIND_ALL_FAIL';

export const PAYMENT_METHODS_GET_DETAILS = 'PAYMENT_METHODS_GET_DETAILS';
export const PAYMENT_METHODS_GET_DETAILS_SUCCESS = 'PAYMENT_METHODS_GET_DETAILS_SUCCESS';
export const PAYMENT_METHODS_GET_DETAILS_FAIL = 'PAYMENT_METHODS_GET_DETAILS_FAIL';

/* Item Groups */
export const ITEM_GROUPS_CREATE = 'ITEM_GROUPS_CREATE';
export const ITEM_GROUPS_CREATE_SUCCESS = 'ITEM_GROUPS_CREATE_SUCCESS';
export const ITEM_GROUPS_CREATE_FAIL = 'ITEM_GROUPS_CREATE_FAIL';

export const ITEM_GROUPS_UPDATE = 'ITEM_GROUPS_UPDATE';
export const ITEM_GROUPS_UPDATE_SUCCESS = 'ITEM_GROUPS_UPDATE_SUCCESS';
export const ITEM_GROUPS_UPDATE_FAIL = 'ITEM_GROUPS_UPDATE_FAIL';

export const ITEM_GROUPS_FIND_ALL_BY_PAGE_AND_OPTIONS = 'ITEM_GROUPS_FIND_ALL_BY_PAGE_AND_OPTIONS';
export const ITEM_GROUPS_FIND_ALL_BY_PAGE_AND_OPTIONS_SUCCESS = 'ITEM_GROUPS_FIND_ALL_BY_PAGE_AND_OPTIONS_SUCCESS';
export const ITEM_GROUPS_FIND_ALL_BY_PAGE_AND_OPTIONS_FAIL = 'ITEM_GROUPS_FIND_ALL_BY_PAGE_AND_OPTIONS_FAIL';

export const ITEM_GROUPS_GET_DETAILS = 'ITEM_GROUPS_GET_DETAILS';
export const ITEM_GROUPS_GET_DETAILS_SUCCESS = 'ITEM_GROUPS_GET_DETAILS_SUCCESS';
export const ITEM_GROUPS_GET_DETAILS_FAIL = 'ITEM_GROUPS_GET_DETAILS_FAIL';

/* Member Groups */
export const MEMBER_GROUPS_CREATE = 'MEMBER_GROUPS_CREATE';
export const MEMBER_GROUPS_CREATE_SUCCESS = 'MEMBER_GROUPS_CREATE_SUCCESS';
export const MEMBER_GROUPS_CREATE_FAIL = 'MEMBER_GROUPS_CREATE_FAIL';

export const MEMBER_GROUPS_UPDATE = 'MEMBER_GROUPS_UPDATE';
export const MEMBER_GROUPS_UPDATE_SUCCESS = 'MEMBER_GROUPS_UPDATE_SUCCESS';
export const MEMBER_GROUPS_UPDATE_FAIL = 'MEMBER_GROUPS_UPDATE_FAIL';

export const MEMBER_GROUPS_FIND_ALL_BY_PAGE_AND_OPTIONS = 'MEMBER_GROUPS_FIND_ALL_BY_PAGE_AND_OPTIONS';
export const MEMBER_GROUPS_FIND_ALL_BY_PAGE_AND_OPTIONS_SUCCESS = 'MEMBER_GROUPS_FIND_ALL_BY_PAGE_AND_OPTIONS_SUCCESS';
export const MEMBER_GROUPS_FIND_ALL_BY_PAGE_AND_OPTIONS_FAIL = 'MEMBER_GROUPS_FIND_ALL_BY_PAGE_AND_OPTIONS_FAIL';

export const MEMBER_GROUPS_GET_DETAILS = 'MEMBER_GROUPS_GET_DETAILS';
export const MEMBER_GROUPS_GET_DETAILS_SUCCESS = 'MEMBER_GROUPS_GET_DETAILS_SUCCESS';
export const MEMBER_GROUPS_GET_DETAILS_FAIL = 'MEMBER_GROUPS_GET_DETAILS_FAIL';

/* Promotions */
export const PROMOTIONS_CREATE = 'PROMOTIONS_CREATE';
export const PROMOTIONS_CREATE_SUCCESS = 'PROMOTIONS_CREATE_SUCCESS';
export const PROMOTIONS_CREATE_FAIL = 'PROMOTIONS_CREATE_FAIL';

export const PROMOTIONS_UPDATE = 'PROMOTIONS_UPDATE';
export const PROMOTIONS_UPDATE_SUCCESS = 'PROMOTIONS_UPDATE_SUCCESS';
export const PROMOTIONS_UPDATE_FAIL = 'PROMOTIONS_UPDATE_FAIL';

export const PROMOTIONS_COPY = 'PROMOTIONS_COPY';
export const PROMOTIONS_COPY_SUCCESS = 'PROMOTIONS_COPY_SUCCESS';
export const PROMOTIONS_COPY_FAIL = 'PROMOTIONS_COPY_FAIL';

export const PROMOTIONS_FIND_ALL_BY_PAGE_AND_OPTIONS = 'PROMOTIONS_FIND_ALL_BY_PAGE_AND_OPTIONS';
export const PROMOTIONS_FIND_ALL_BY_PAGE_AND_OPTIONS_SUCCESS = 'PROMOTIONS_FIND_ALL_BY_PAGE_AND_OPTIONS_SUCCESS';
export const PROMOTIONS_FIND_ALL_BY_PAGE_AND_OPTIONS_FAIL = 'PROMOTIONS_FIND_ALL_BY_PAGE_AND_OPTIONS_FAIL';

export const PROMOTIONS_GET_DETAILS = 'PROMOTIONS_GET_DETAILS';
export const PROMOTIONS_GET_DETAILS_SUCCESS = 'PROMOTIONS_GET_DETAILS_SUCCESS';
export const PROMOTIONS_GET_DETAILS_FAIL = 'PROMOTIONS_GET_DETAILS_FAIL';

/* Messages */
export const MESSAGES_CREATE = 'MESSAGES_CREATE';
export const MESSAGES_CREATE_SUCCESS = 'MESSAGES_CREATE_SUCCESS';
export const MESSAGES_CREATE_FAIL = 'MESSAGES_CREATE_FAIL';

export const MESSAGES_UPDATE = 'MESSAGES_UPDATE';
export const MESSAGES_UPDATE_SUCCESS = 'MESSAGES_UPDATE_SUCCESS';
export const MESSAGES_UPDATE_FAIL = 'MESSAGES_UPDATE_FAIL';

export const MESSAGES_FIND_ALL_BY_PAGE_AND_OPTIONS = 'MESSAGES_FIND_ALL_BY_PAGE_AND_OPTIONS';
export const MESSAGES_FIND_ALL_BY_PAGE_AND_OPTIONS_SUCCESS = 'MESSAGES_FIND_ALL_BY_PAGE_AND_OPTIONS_SUCCESS';
export const MESSAGES_FIND_ALL_BY_PAGE_AND_OPTIONS_FAIL = 'MESSAGES_FIND_ALL_BY_PAGE_AND_OPTIONS_FAIL';

export const MESSAGES_GET_DETAILS = 'MESSAGES_GET_DETAILS';
export const MESSAGES_GET_DETAILS_SUCCESS = 'MESSAGES_GET_DETAILS_SUCCESS';
export const MESSAGES_GET_DETAILS_FAIL = 'MESSAGES_GET_DETAILS_FAIL';

/* Transactions */
export const TRANSACTIONS_FIND_ALL_BY_PAGE_AND_OPTIONS = 'TRANSACTIONS_FIND_ALL_BY_PAGE_AND_OPTIONS';
export const TRANSACTIONS_FIND_ALL_BY_PAGE_AND_OPTIONS_SUCCESS = 'TRANSACTIONS_FIND_ALL_BY_PAGE_AND_OPTIONS_SUCCESS';
export const TRANSACTIONS_FIND_ALL_BY_PAGE_AND_OPTIONS_FAIL = 'TRANSACTIONS_FIND_ALL_BY_PAGE_AND_OPTIONS_FAIL';

export const TRANSACTIONS_GET_DETAILS = 'TRANSACTIONS_GET_DETAILS';
export const TRANSACTIONS_GET_DETAILS_SUCCESS = 'TRANSACTIONS_GET_DETAILS_SUCCESS';
export const TRANSACTIONS_GET_DETAILS_FAIL = 'TRANSACTIONS_GET_DETAILS_FAIL';

export const TRANSACTIONS_PRINT = 'TRANSACTIONS_PRINT';
export const TRANSACTIONS_PRINT_SUCCESS = 'TRANSACTIONS_PRINT_SUCCESS';
export const TRANSACTIONS_PRINT_FAIL = 'TRANSACTIONS_PRINT_FAIL';

/* Config */
export const CONFIGS_FIND_ALL_BY_GROUP = 'CONFIGS_FIND_ALL_BY_GROUP';
export const CONFIGS_FIND_ALL_BY_GROUP_SUCCESS = 'CONFIGS_FIND_ALL_BY_GROUP_SUCCESS';
export const CONFIGS_FIND_ALL_BY_GROUP_FAIL = 'CONFIGS_FIND_ALL_BY_GROUP_FAIL';

export const CONFIGS_BATCH_SET_CONFIG = 'CONFIGS_BATCH_SET_CONFIG';
export const CONFIGS_BATCH_SET_CONFIG_SUCCESS = 'CONFIGS_BATCH_SET_CONFIG_SUCCESS';
export const CONFIGS_BATCH_SET_CONFIG_FAIL = 'CONFIGS_BATCH_SET_CONFIG_FAIL';

/* Max brand discount */
export const MAX_BRAND_DISCOUNTS_CREATE = 'MAX_BRAND_DISCOUNTS_CREATE';
export const MAX_BRAND_DISCOUNTS_CREATE_SUCCESS = 'MAX_BRAND_DISCOUNTS_CREATE_SUCCESS';
export const MAX_BRAND_DISCOUNTS_CREATE_FAIL = 'MAX_BRAND_DISCOUNTS_CREATE_FAIL';

export const MAX_BRAND_DISCOUNTS_UPDATE = 'MAX_BRAND_DISCOUNTS_UPDATE';
export const MAX_BRAND_DISCOUNTS_UPDATE_SUCCESS = 'MAX_BRAND_DISCOUNTS_UPDATE_SUCCESS';
export const MAX_BRAND_DISCOUNTS_UPDATE_FAIL = 'MAX_BRAND_DISCOUNTS_UPDATE_FAIL';

export const MAX_BRAND_DISCOUNTS_FIND_ALL_BY_PAGE_AND_OPTIONS = 'MAX_BRAND_DISCOUNTS_FIND_ALL_BY_PAGE_AND_OPTIONS';
export const MAX_BRAND_DISCOUNTS_FIND_ALL_BY_PAGE_AND_OPTIONS_SUCCESS = 'MAX_BRAND_DISCOUNTS_FIND_ALL_BY_PAGE_AND_OPTIONS_SUCCESS';
export const MAX_BRAND_DISCOUNTS_FIND_ALL_BY_PAGE_AND_OPTIONS_FAIL = 'MAX_BRAND_DISCOUNTS_FIND_ALL_BY_PAGE_AND_OPTIONS_FAIL';

export const MAX_BRAND_DISCOUNTS_GET_DETAILS = 'MAX_BRAND_DISCOUNTS_GET_DETAILS';
export const MAX_BRAND_DISCOUNTS_GET_DETAILS_SUCCESS = 'MAX_BRAND_DISCOUNTS_GET_DETAILS_SUCCESS';
export const MAX_BRAND_DISCOUNTS_GET_DETAILS_FAIL = 'MAX_BRAND_DISCOUNTS_GET_DETAILS_FAIL';

/* Remarks */
export const REMARKS_CREATE = 'REMARKS_CREATE';
export const REMARKS_CREATE_SUCCESS = 'REMARKS_CREATE_SUCCESS';
export const REMARKS_CREATE_FAIL = 'REMARKS_CREATE_FAIL';

export const REMARKS_UPDATE = 'REMARKS_UPDATE';
export const REMARKS_UPDATE_SUCCESS = 'REMARKS_UPDATE_SUCCESS';
export const REMARKS_UPDATE_FAIL = 'REMARKS_UPDATE_FAIL';

export const REMARKS_FIND_ALL_BY_PAGE_AND_OPTIONS = 'REMARKS_FIND_ALL_BY_PAGE_AND_OPTIONS';
export const REMARKS_FIND_ALL_BY_PAGE_AND_OPTIONS_SUCCESS = 'REMARKS_FIND_ALL_BY_PAGE_AND_OPTIONS_SUCCESS';
export const REMARKS_FIND_ALL_BY_PAGE_AND_OPTIONS_FAIL = 'REMARKS_FIND_ALL_BY_PAGE_AND_OPTIONS_FAIL';

export const REMARKS_GET_DETAILS = 'REMARKS_GET_DETAILS';
export const REMARKS_GET_DETAILS_SUCCESS = 'REMARKS_GET_DETAILS_SUCCESS';
export const REMARKS_GET_DETAILS_FAIL = 'REMARKS_GET_DETAILS_FAIL';

/* Purposes */
export const PURPOSES_CREATE = 'PURPOSES_CREATE';
export const PURPOSES_CREATE_SUCCESS = 'PURPOSES_CREATE_SUCCESS';
export const PURPOSES_CREATE_FAIL = 'PURPOSES_CREATE_FAIL';

export const PURPOSES_UPDATE = 'PURPOSES_UPDATE';
export const PURPOSES_UPDATE_SUCCESS = 'PURPOSES_UPDATE_SUCCESS';
export const PURPOSES_UPDATE_FAIL = 'PURPOSES_UPDATE_FAIL';

export const PURPOSES_FIND_ALL_BY_PAGE_AND_OPTIONS = 'PURPOSES_FIND_ALL_BY_PAGE_AND_OPTIONS';
export const PURPOSES_FIND_ALL_BY_PAGE_AND_OPTIONS_SUCCESS = 'PURPOSES_FIND_ALL_BY_PAGE_AND_OPTIONS_SUCCESS';
export const PURPOSES_FIND_ALL_BY_PAGE_AND_OPTIONS_FAIL = 'PURPOSES_FIND_ALL_BY_PAGE_AND_OPTIONS_FAIL';

export const PURPOSES_GET_DETAILS = 'PURPOSES_GET_DETAILS';
export const PURPOSES_GET_DETAILS_SUCCESS = 'PURPOSES_GET_DETAILS_SUCCESS';
export const PURPOSES_GET_DETAILS_FAIL = 'PURPOSES_GET_DETAILS_FAIL';
