module.exports = {
	/* General */
	'general.copyright': '© 2019 All Rights Reserved.',

	/* User Login, Logout, Register */
	'user.login-title': 'Watch POS Backend Login',
	'user.register': 'Register',
	'user.forgot-password': 'Forgot Password',
	'user.username': 'Username',
	'user.password': 'Password',
	'user.forgot-password-question': 'Forget password?',
	'user.fullname': 'Full Name',
	'user.login-button': 'LOGIN',
	'user.register-button': 'REGISTER',
	'user.reset-password-button': 'RESET',

	/* Menu */
	'menu.change-password': 'Change Password',
	'menu.company': 'Company',
	'menu.warehouse': 'Warehouse',
	'menu.warehouse.warehouse-list': 'Warehouse List',
	'menu.warehouse.manage-warehouse-group': 'Manage Warehouse Group',
	'menu.payment': 'Payment',
	'menu.item-group': 'Item Group',
	'menu.member-group': 'Member Group',
	'menu.promotion': 'Promotion',
	'menu.message': 'Message',
	'menu.transaction': 'Transaction',
	'menu.user': 'User',
	'menu.user.find-user': 'User List',
	'menu.user.manage-role': 'Manage Role',
	'menu.config': 'Config',
	'menu.config.max-brand-discount': 'Maximum Brand Discount',
	'menu.config.password-rules': 'Password Rules',
	'menu.config.remark-templates': 'Remark Templates',
	'menu.config.purposes': 'Purposes',
	'menu.remark': 'Remark Templates',

	/* Change Password */
	'change-password.heading': 'Change Password',

	/* Home */
	'home.heading': 'Home',

	/* Companies */
	'companies.heading': 'Manage Companies',
	'companies.add-new': 'ADD NEW',
	'companies.list.order-by': 'Order By',
	'companies.list.order-by.name': 'Name',
	'companies.list.order-by.category': 'Category',
	'companies.list.order-by.created-date': 'Created Date',

	/* Company */
	'company.heading': 'Company Details',

	/* Warehouses */
	'warehouses.heading': 'Manage Warehouses',

	/* Warehouse */
	'warehouse.heading': 'Warehouse Details',

	/* Warehouse Groups */
	'warehouse-groups.heading': 'Manage Warehouse Groups',

	/* Warehouse Group */
	'warehouse-group.heading': 'Warehouse Group Details',

	/* Payments */
	'payments.heading': 'Manage Payment Methods',

	/* Payment */
	'payment.heading': 'Payment Method Details',

	/* Item Groups */
	'item-groups.heading': 'Manage Item Groups',

	/* Item Group */
	'item-group.heading': 'Item Group Details',

	/* Member Groups */
	'member-groups.heading': 'Manage Member Groups',

	/* Member Group */
	'member-group.heading': 'Member Group Details',

	/* Promotions */
	'promotions.heading': 'Manage Promotions',

	/* Promotion */
	'promotion.heading': 'Promotion Details',

	/* Messages */
	'messages.heading': 'Manage Messages',

	/* Message */
	'message.heading': 'Message Details',

	/* Transactions */
	'transactions.heading': 'View Transactions',

	/* Transaction */
	'transaction.heading': 'Transaction Details',
	'transaction-print.heading': 'Print',

	/* Users */
	'users.heading': 'Manage Users',

	/* User */
	'user.heading': 'User Details',

	/* User Permission */
	'user-permission.heading': 'Final Permission',

	/* User Roles */
	'user-roles.heading': 'Manage User Roles',

	/* User Role */
	'user-role.heading': 'User Role Details',

	/* Maximum Brand Discount */
	'config.max-brand-discount.heading': 'Maximum Brand Discount',

	/* Password Rules */
	'config.password-rules.heading': 'Password Rules',
	'config.password-rules.password-rule-security': 'Password Rule Security',

	/* Remark */
	'remark.heading': 'Remark Template',

	/* Purposes */
	'config.purposes.heading': 'Manage Purposes',
	'config.purpose.heading': 'Purpose Details',

	/* Error */
	'error.heading': 'Ooops...looks like an error occurred!',
	'error.code': 'Error Code',
	'error.go-back-home': 'GO BACK HOME',
	'TOKEN_EXPIRED': 'The access token is expired. Please login again.',
	'TOKEN_INVALID': 'The access token is invalid. Please login again.',
	'Invalid Request': 'Missing or invalid data. Please check the errors and try again.',
};
