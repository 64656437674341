import React, { Component } from 'react';
import IntlMessages from 'Util/IntlMessages';
import { Nav, NavItem } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import classnames from 'classnames';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Spinner from 'Components/Spinner';
import {
	setContainerClassnames,
	addContainerClassname,
	changeDefaultClassnames,
	changeSelectedMenuHasSubItems
} from 'Redux/actions';

class Sidebar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedParentMenu: '',
			viewingParentMenu: '',
		};
		this.ref = React.createRef();
		this.handleWindowResize = this.handleWindowResize.bind(this);
		this.addEvents = this.addEvents.bind(this);
		this.removeEvents = this.removeEvents.bind(this);
		this.handleDocumentClick = this.handleDocumentClick.bind(this);
		this.toggle = this.toggle.bind(this);
		this.handleProps = this.handleProps.bind(this);
		this.getContainer = this.getContainer.bind(this);
		this.getMenuClassesForResize = this.getMenuClassesForResize.bind(this);
		this.setSelectedLiActive = this.setSelectedLiActive.bind(this);
		this.hideSubmenu = this.hideSubmenu.bind(this);
		this.navItems = [
			{
				id: 'menu.company',
				icon: 'iconsminds-building',
				href: '/company',
				permission: 'backend_company',
			},
			{
				id: 'menu.warehouse',
				icon: 'iconsminds-shop-3',
				href: '/warehouse',
				permission: 'backend_warehouse',
				items: [
					{
						id: 'menu.warehouse.warehouse-list',
						icon: 'iconsminds-shop-3',
						href: '/warehouse/list',
					},
					{
						id: 'menu.warehouse.manage-warehouse-group',
						icon: 'simple-icon-organization',
						className: 'menu-simple-icon',
						href: '/warehouse/group/list',
					},
				]
			},
			{
				id: 'menu.payment',
				icon: 'iconsminds-credit-card',
				href: '/payment',
				permission: 'backend_payment',
			},
			{
				id: 'menu.item-group',
				icon: 'iconsminds-glasses-3',
				href: '/item-group',
				permission: 'backend_item_group',
			},
			{
				id: 'menu.member-group',
				icon: 'iconsminds-gey',
				href: '/member-group',
				permission: 'backend_member_group',
			},
			// {
			// 	id: 'menu.promotion',
			// 	icon: 'iconsminds-checkout-basket',
			// 	href: '/promotion',
			// 	permission: 'backend_promotion',
			// },
			{
				id: 'menu.message',
				icon: 'simple-icon-bell',
				href: '/message',
				permission: 'backend_message',
			},
			{
				id: 'menu.transaction',
				icon: 'iconsminds-receipt-4',
				href: '/transaction',
				permission: 'backend_transaction',
			},
			{
				id: 'menu.user',
				icon: 'iconsminds-male-2',
				href: '/user',
				permission: 'backend_user',
				items: [
					{
						id: 'menu.user.find-user',
						icon: 'simple-icon-people',
						className: 'menu-simple-icon',
						href: '/user/list',
					},
					{
						id: 'menu.user.manage-role',
						icon: 'simple-icon-user-follow',
						className: 'menu-simple-icon',
						href: '/user-role/list',
					},
				]
			},
			{
				id: 'menu.config',
				icon: 'simple-icon-settings',
				href: '/config',
				permission: 'backend_config',
				items: [
					{
						id: 'menu.config.max-brand-discount',
						icon: 'iconsminds-dollar-sign-2',
						href: '/config/max-brand-discount',
					},
					{
						id: 'menu.config.password-rules',
						icon: 'iconsminds-password-field',
						href: '/config/password-rules',
					},
					{
						id: 'menu.config.remark-templates',
						icon: 'iconsminds-file-edit',
						href: '/remark',
					},
					// {
					// 	id: 'menu.config.purposes',
					// 	icon: 'iconsminds-target',
					// 	href: '/config/purposes',
					// },
				]
			},
		];
	}

	handleWindowResize(event) {
		if (event && !event.isTrusted) {
			return;
		}
		const { containerClassnames } = this.props;
		let nextClasses = this.getMenuClassesForResize(containerClassnames);
		this.props.setContainerClassnames(0, nextClasses.join(' '), this.props.selectedMenuHasSubItems);
	}

	handleDocumentClick(e) {
		const container = this.getContainer();
		let isMenuClick = false;
		if (
			e.target && e.target.classList &&
			(e.target.classList.contains('menu-button') || e.target.classList.contains('menu-button-mobile'))
		) {
			isMenuClick = true;
		} else if (
			e.target.parentElement && e.target.parentElement.classList &&
			(e.target.parentElement.classList.contains('menu-button') || e.target.parentElement.classList.contains('menu-button-mobile'))
		) {
			isMenuClick = true;
		} else if (
			e.target.parentElement && e.target.parentElement.parentElement &&
			e.target.parentElement.parentElement.classList && (e.target.parentElement.parentElement.classList.contains('menu-button') || e.target.parentElement.parentElement.classList.contains('menu-button-mobile'))
		) {
			isMenuClick = true;
		}
		if ((container.current.contains(e.target) || container === e.target) || isMenuClick) {
			return;
		}
		this.toggle(e);
		this.setState({
			viewingParentMenu: ''
		});

		this.hideSubmenu();
	}

	getMenuClassesForResize(classes) {
		const { menuHiddenBreakpoint, subHiddenBreakpoint } = this.props;
		let nextClasses = classes.split(' ').filter(x => x != '');
		const windowWidth = window.innerWidth;
		if (windowWidth < menuHiddenBreakpoint) {
			nextClasses.push('menu-mobile');
		} else if (windowWidth < subHiddenBreakpoint) {
			nextClasses = nextClasses.filter(x => x != 'menu-mobile');
			if (nextClasses.includes('menu-default') && !nextClasses.includes('menu-sub-hidden')) {
				nextClasses.push('menu-sub-hidden');
			}
		} else {
			nextClasses = nextClasses.filter(x => x != 'menu-mobile');
			if (nextClasses.includes('menu-default') && nextClasses.includes('menu-sub-hidden')) {
				nextClasses = nextClasses.filter(x => x != 'menu-sub-hidden');
			}
		}
		return nextClasses;
	}

	getContainer() {
		return this.ref;
	}

	toggle() {
		const { containerClassnames, menuClickCount } = this.props;
		const currentClasses = containerClassnames ? containerClassnames.split(' ').filter(x => x != '') : '';

		if (currentClasses.includes('menu-sub-hidden') && menuClickCount == 3) {
			this.props.setContainerClassnames(2, containerClassnames, this.props.selectedMenuHasSubItems);
		} else if (currentClasses.includes('menu-hidden') || currentClasses.includes('menu-mobile')) {
			this.props.setContainerClassnames(0, containerClassnames, this.props.selectedMenuHasSubItems);
		}
	}

	handleProps() {
		this.addEvents();
	}

	addEvents() {
		['click', 'touchstart'].forEach(event =>
			document.addEventListener(event, this.handleDocumentClick, true)
		);
	}
	removeEvents() {
		['click', 'touchstart'].forEach(event =>
			document.removeEventListener(event, this.handleDocumentClick, true)
		);
	}
	setSelectedLiActive() {
		const oldli = document.querySelector('.sub-menu  li.active');
		if (oldli != null) {
			oldli.classList.remove('active');
		}

		/* set selected parent menu */
		const selectedlink = document.querySelector('.sub-menu  a.active');
		if (selectedlink != null) {
			selectedlink.parentElement.classList.add('active');
			this.setState({
				selectedParentMenu: selectedlink.parentElement.parentElement.getAttribute(
					'data-parent'
				)
			});
		} else {
			var selectedParentNoSubItem = document.querySelector('.main-menu  li a.active');
			if (selectedParentNoSubItem != null) {
				this.setState({
					selectedParentMenu: selectedParentNoSubItem.getAttribute(
						'data-flag'
					)
				});
			} else if (this.state.selectedParentMenu == '') {
				this.setState({
					selectedParentMenu: ''
				});
			}

		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.location.pathname !== prevProps.location.pathname) {
			this.setSelectedLiActive();
			this.toggle();
			window.scrollTo(0, 0);
		}
		this.handleProps();
	}

	componentDidMount() {
		window.addEventListener('resize', this.handleWindowResize);
		this.handleWindowResize();
		this.handleProps();
		this.setSelectedLiActive();
	}

	componentWillUnmount() {
		this.removeEvents();
		window.removeEventListener('resize', this.handleWindowResize);
	}

	changeDefaultMenuType(e, containerClassnames) {
		e.preventDefault();
		let nextClasses = this.getMenuClassesForResize(containerClassnames);
		this.props.setContainerClassnames(0, nextClasses.join(' '), this.props.selectedMenuHasSubItems);
	}

	openSubMenu(e, selectedParent) {
		e.preventDefault();
		this.props.changeSelectedMenuHasSubItems(true);

		const { containerClassnames, menuClickCount } = this.props;
		const currentClasses = containerClassnames ? containerClassnames.split(' ').filter(x => x != '') : '';

		if (!currentClasses.includes('menu-mobile')) {
			if (currentClasses.includes('menu-sub-hidden') && (menuClickCount == 2 || menuClickCount == 0)) {
				this.props.setContainerClassnames(3, containerClassnames, true);
			} else if (currentClasses.includes('menu-hidden') && (menuClickCount == 1 || menuClickCount == 3)) {
				this.props.setContainerClassnames(2, containerClassnames, true);
			} else if (currentClasses.includes('menu-default') && !currentClasses.includes('menu-sub-hidden') && (menuClickCount == 1 || menuClickCount == 3)) {
				this.props.setContainerClassnames(0, containerClassnames, true);
			}
		} else {
			this.props.addContainerClassname(
				'sub-show-temporary',
				containerClassnames
			);
		}
		this.setState({
			viewingParentMenu: selectedParent
		});
	}

	changeSelectedParentHasNoSubmenu(e, menu) {
		this.setState({
			viewingParentMenu: menu
		});
		this.props.changeSelectedMenuHasSubItems(false);
		this.toggle();
		this.hideSubmenu();
	}

	hideSubmenu() {
		const { containerClassnames } = this.props;
		const currentClasses = containerClassnames ? containerClassnames.split(' ').filter(x => x != '') : '';
		if (!currentClasses.includes('menu-mobile')) {
			this.props.setContainerClassnames(1, containerClassnames, false);
		}
	}

	render() {
		const { match, location, current } = this.props;
		const permissions = current.get('permissions');
		return (
			<div className="sidebar" ref={this.ref}>
				<div className="main-menu">
					<div className="scroll">
						<PerfectScrollbar
							options={{ suppressScrollX: true, wheelPropagation: false }}
						>
							{
								current.size > 0 ? (
									<Nav vertical className="list-unstyled">
										{
											this.navItems.map((navItem) => (
												!(permissions && permissions[navItem.permission] && permissions[navItem.permission] === ' ') && (
													<NavItem
														key={navItem.id}
														className={classnames({
															active: ((this.state.selectedParentMenu == navItem.id && this.state.viewingParentMenu == '') || this.state.viewingParentMenu == navItem.id || location.pathname.indexOf(navItem.href) >= 0)
														})}
													>
														<NavLink
															to={`${match.url}${navItem.href}`}
															onClick={navItem.items ? (e => this.openSubMenu(e, navItem.id)) : (e => this.changeSelectedParentHasNoSubmenu(e, navItem.id))}
														>
															<i className={navItem.icon} />{' '}
															<IntlMessages id={navItem.id} />
														</NavLink>
													</NavItem>
												)
											))
										}
									</Nav>
								) : (
									<div className="my-4">
										<Spinner />
									</div>
								)
							}
						</PerfectScrollbar>
					</div>
				</div>

				<div className="sub-menu">
					<div className="scroll">
						<PerfectScrollbar
							options={{ suppressScrollX: true, wheelPropagation: false }}
						>
							{
								this.navItems.map((navItem) => (
									navItem.items ? (
										<Nav
											key={`${navItem.id}-subItems`}
											className={classnames({
												'd-block': ((this.state.selectedParentMenu == navItem.id && this.state.viewingParentMenu == '') || this.state.viewingParentMenu == navItem.id)
											})}
											data-parent={navItem.id}
										>
											{
												navItem.items.map((subNavItem) => (
													<NavItem key={subNavItem.id}>
														<NavLink to={`${match.url}${subNavItem.href}`} onClick={this.hideSubmenu}>
															<i className={classnames(subNavItem.icon, subNavItem.className)} />{' '}
															<IntlMessages id={subNavItem.id} />
														</NavLink>
													</NavItem>
												))
											}
										</Nav>
									) : null
								))
							}
						</PerfectScrollbar>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = ({ menu, users }) => {
	const { containerClassnames, subHiddenBreakpoint, menuHiddenBreakpoint, menuClickCount, selectedMenuHasSubItems } = menu;
	const { current } = users;
	return { containerClassnames, subHiddenBreakpoint, menuHiddenBreakpoint, menuClickCount, selectedMenuHasSubItems, current };
};
export default withRouter(
	connect(
		mapStateToProps,
		{ setContainerClassnames, addContainerClassname, changeDefaultClassnames, changeSelectedMenuHasSubItems }
	)(Sidebar)
);
