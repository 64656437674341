import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Map } from 'immutable';
import classnames from 'classnames';
import moment from 'moment';
import { Row, Nav, NavItem, TabContent, TabPane, Card, CardBody, Form, Input, Button } from 'reactstrap';
import CustomFormGroup from 'Components/CustomFormGroup';
import BreadcrumbContainer from 'Components/BreadcrumbContainer';
import { Colxx, Separator } from 'Components/CustomBootstrap';
import { NotificationManager } from 'Components/ReactNotifications';
import LoadingOverlay from 'Components/LoadingOverlay';
import IntlMessages from 'Util/IntlMessages';
import {
	memberGroupsCreate,
	memberGroupsUpdate,
	memberGroupsGetDetails,
	memberGroupsGetOptions,
	errorReset,
} from 'Redux/actions';
import * as memberGroupsReducer from 'Redux/memberGroups/reducer';

class PageMemberGroup extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			activeTab: 'details',
			memberGroup: new Map({
				id: null,
				status: null,
				name: '',
				description: '',
				group_type: null,
				mbr_codes: [],
			}),
		};
		this.fields = [
			{ id: 'name', type: 'text', label: 'Name' },
			{ id: 'description', type: 'textarea', label: 'Description' },
			{ id: 'group_type', type: 'react-select', label: 'Group Type', onChange: this.onChangeGroupTypeHandler.bind(this) },
			{ id: 'status', type: 'react-select', label: 'Status', onChange: this.onChangeStatusHandler.bind(this) },
		];
		this.breadcrumb = [
			{ id: 'menu.member-group', href: '/member-group/list' },
			{ id: 'member-group.heading', href: `/member-group/${this.getId(props) ? `edit/${this.getId(props)}` : 'create'}` },
		];
		this.getId = this.getId.bind(this);
		this.onSelectDetailsTabHandler = this.onSelectTabHandler.bind(this, 'details');
		this.onChangeHandler = this.onChangeHandler.bind(this);
		this.onSubmitHandler = this.onSubmitHandler.bind(this);
	}

	componentDidMount() {
		const id = this.getId();
		if (id) {
			this.props.memberGroupsGetDetails(id);
		}
		this.props.memberGroupsGetOptions();
		this.props.errorReset('memberGroup');
	}

	componentDidUpdate(prevProps) {
		const id = this.getId();
		if (id) {
			const prevMemberGroup = prevProps.memberGroup.get(id);
			const memberGroup = this.props.memberGroup.get(id);
			if (memberGroup && memberGroup !== prevMemberGroup) {
				this.setState({
					memberGroup: this.state.memberGroup.merge(memberGroup),
				});
			}
		}

		const current = this.props.current;
		const prevStatus = prevProps.status.get('memberGroup');
		const status = this.props.status.get('memberGroup');
		if (status && prevStatus !== status) {
			switch (status) {
			case 'submitted': {
				NotificationManager.success(
					`The member group is ${id ? 'updated' : 'created'} successfully.`,
					`Member Group ${id ? 'Updated' : 'Created'}`
				);
				this.props.history.push(`/app/member-group/edit/${current}`);
				const memberGroup = this.props.memberGroup.get(current);
				this.setState({
					memberGroup: this.state.memberGroup.merge(memberGroup),
				});
			}
				break;
			case 'has-errors':
				NotificationManager.error(
					`Error occured! The member group is NOT ${id ? 'updated' : 'created'}.`,
					`Member Group ${id ? 'Updated' : 'Created'} Failed`
				);
				break;
			}
		}
	}

	getId(props = null) {
		if (!props) {
			props = this.props;
		}
		return parseInt(props.match.params.memberGroupId);
	}

	onSelectTabHandler(tab) {
		this.setState({
			activeTab: tab,
		});
	}

	onChangeHandler(e) {
		let { memberGroup } = this.state;
		let value;
		switch (e.target.id) {
		case 'mbr_codes':
			value = e.target.value.split(/[\n,]/).map((v) => v.trim());
			break;
		default:
			value = e.target.value;
			break;
		}
		this.setState({
			memberGroup: memberGroup.set(e.target.id, value),
		});
	}

	onChangeGroupTypeHandler(selectedOption) {
		let { memberGroup } = this.state;
		this.setState({
			memberGroup: memberGroup.set('group_type', selectedOption),
		});
	}

	onChangeStatusHandler(selectedOption) {
		let { memberGroup } = this.state;
		this.setState({
			memberGroup: memberGroup.set('status', selectedOption),
		});
	}

	onSubmitHandler(e) {
		e.preventDefault();
		const id = this.getId();
		let memberGroup = this.state.memberGroup.toJS();
		memberGroup.status = memberGroup.status ? memberGroup.status.value : null;
		memberGroup.group_type = memberGroup.group_type ? memberGroup.group_type.value : null;
		if (!id) {
			this.props.memberGroupsCreate(memberGroup);
		} else {
			this.props.memberGroupsUpdate(memberGroup);
		}
	}

	render() {
		const { options, errors } = this.props;
		const { activeTab, memberGroup } = this.state;
		const status = this.props.status.get('memberGroup');
		const optionsStatus = this.props.status.get('options');
		const isLoading = status === 'fetching' || status === 'submitting' || optionsStatus === 'fetching';
		return (
			<Fragment>
				<Row>
					<Colxx xxs="12">
						<BreadcrumbContainer
							heading={<IntlMessages id="member-group.heading" />}
							items={this.breadcrumb}
							match={this.props.match}
						/>
					</Colxx>
				</Row>

				<Separator className="mb-5" />

				<LoadingOverlay active={isLoading}>
					<Nav tabs className="separator-tabs ml-0 mb-5">
						<NavItem>
							<NavLink
								className={classnames({
									active: activeTab === 'details',
									'nav-link': true
								})}
								onClick={this.onSelectDetailsTabHandler}
								to="#"
							>
								DETAILS
							</NavLink>
						</NavItem>
					</Nav>

					<TabContent activeTab={activeTab}>
						<TabPane tabId="details">
							<Form onSubmit={this.onSubmitHandler}>
								<Row>
									<Colxx lg="4">
										<Card>
											<CardBody>
												<h4>Member Group Config</h4>

												{
													this.fields.map((field) => (
														<CustomFormGroup
															key={`page-member-group-${field.id}`}
															id={field.id}
															label={field.label}
															type={field.type}
															value={memberGroup.get(field.id)}
															disabled={isLoading}
															errors={errors && errors.detail[field.id]}
															onChange={field.onChange ? field.onChange : this.onChangeHandler}
															options={options.get(field.id)}
															isMulti={field.isMulti}
														/>
													))
												}

												{
													memberGroup.get('updated_on') && (
														<p>Updated at {moment(memberGroup.get('updated_on')).format('YYYY-MM-DD HH:mm:ss')}</p>
													)
												}
											</CardBody>
										</Card>
									</Colxx>
									<Colxx lg="8">
										<Card>
											<CardBody>
												<h4>Item List</h4>
												<p>Separated by commas or newline characters</p>
												<Input
													type="textarea"
													id="mbr_codes"
													rows="10"
													value={memberGroup.get('mbr_codes').join('\n') || ''}
													disabled={isLoading}
													onChange={this.onChangeHandler}
													invalid={errors && errors.detail && errors.detail.mbr_codes}
												/>
												{
													errors && errors.detail && errors.detail.mbr_codes && (
														<div className="invalid-feedback d-block">
															{
																errors.detail.mbr_codes.map((error, index) => (
																	<Fragment key={`page-member-group-mbr_codes-error-${index}`}>
																		{index > 0 && <br />}
																		{error}
																	</Fragment>
																))
															}
														</div>
													)
												}
											</CardBody>
										</Card>
									</Colxx>
								</Row>

								<div className="mt-4 text-center">
									<Button size="lg" color="primary" onClick={this.onSubmitHandler} disabled={isLoading}>
										SAVE
									</Button>
								</div>
							</Form>
						</TabPane>
					</TabContent>
				</LoadingOverlay>
			</Fragment>
		);
	}
}

export default connect(
	memberGroupsReducer.selector,
	{
		memberGroupsCreate,
		memberGroupsUpdate,
		memberGroupsGetDetails,
		memberGroupsGetOptions,
		errorReset,
	}
)(PageMemberGroup);
