import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Row, Card, CardBody, Form, FormGroup, Label, Input } from 'reactstrap';
import { Colxx, Separator } from 'Components/CustomBootstrap';
import { Map } from 'immutable';
import DatePicker from 'react-datepicker';
import Switch from 'rc-switch';

class FormMessageDetails extends PureComponent {
	constructor() {
		super();
		this.onChangeTextHandler = this.onChangeTextHandler.bind(this);
		this.onChangePeriodStartHandler = this.onChangePeriodStartHandler.bind(this);
		this.onChangePeriodEndHandler = this.onChangePeriodEndHandler.bind(this);
		this.onChangeStatusHandler = this.onChangeStatusHandler.bind(this);
		this.onChangeEnableRemarkHandler = this.onChangeEnableRemarkHandler.bind(this);
		this.renderErrors = this.renderErrors.bind(this);
	}

	onChangeTextHandler(e) {
		let value;
		switch (e.target.id) {
		case 'level':
		case 'max_app_per_invoice':
		case 'max_app_per_product_line':
			value = parseInt(e.target.value);
			if (isNaN(value)) {
				value = 0;
			}
			break;
		default:
			value = e.target.value;
			break;
		}
		this.props.onChange(e.target.id, value);
	}

	onChangePeriodStartHandler(value) {
		value = value.set({
			hour: 0,
			minute: 0,
			second: 0,
		});
		this.props.onChange('period_start', value);
	}

	onChangePeriodEndHandler(value) {
		value = value.set({
			hour: 23,
			minute: 59,
			second: 59,
		});
		this.props.onChange('period_end', value);
	}

	onChangeStatusHandler() {
		const { message } = this.props;
		const value = message.get('status') ? 0 : 1;
		this.props.onChange('status', value);
	}

	onChangeEnableRemarkHandler() {
		const { message } = this.props;
		const value = message.get('enable_remark');
		this.props.onChange('enable_remark', !value);
	}

	renderErrors(id) {
		let { errors } = this.props;
		if (errors && errors.detail && errors.detail[id]) {
			errors = errors.detail[id];
			return (
				<div className="invalid-feedback d-block">
					{
						errors.map((error, index) => (
							<Fragment key={ `form-message-details-${id}-error-${index}` }>
								{ index > 0 && <br /> }
								{ error }
							</Fragment>
						))
					}
				</div>
			);
		}
		return null;
	}

	render() {
		const { message, isLoading } = this.props;
		return (
			<Form onSubmit={ this.onSubmitHandler }>
				<Row>
					<Colxx xxs="12">
						<Card className="mb-5">
							<CardBody>
								<h5 className="mb-4">Message Settings</h5>

								<Row>
									<Colxx sm="6">
										<Label for="name" className="form-group has-float-label">
											<Input
												type="text"
												id="name"
												value={ message.get('name') }
												disabled={ isLoading }
												onChange={ this.onChangeTextHandler }
												placeholder="Title"
											/>
											<span>Title</span>
											{ this.renderErrors('name') }
										</Label>

										<Row>
											<Colxx xxs="6">
												<Label for="level" className="form-group has-float-label">
													<DatePicker
														selected={ message.get('period_start') }
														selectsStart
														startDate={ message.get('period_start') }
														endDate={ message.get('period_end') }
														onChange={ this.onChangePeriodStartHandler }
														placeholderText="Start"
														dateFormat="YYYY-MM-DD HH:mm:ss"
													/>
													<span>Message Period</span>
													{ this.renderErrors('period_start') }
												</Label>
											</Colxx>
											<Colxx xxs="6">
												<DatePicker
													selected={ message.get('period_end') }
													selectsEnd
													startDate={ message.get('period_start') }
													endDate={ message.get('period_end') }
													onChange={ this.onChangePeriodEndHandler }
													placeholderText="End"
													dateFormat="YYYY-MM-DD HH:mm:ss"
												/>
												{ this.renderErrors('period_end') }
											</Colxx>
										</Row>

										<Label for="level" className="form-group has-float-label">
											<Input
												type="text"
												id="level"
												value={ message.get('level') }
												disabled={ isLoading }
												onChange={ this.onChangeTextHandler }
												placeholder="0 - 99"
											/>
											<span>Message Level</span>
										</Label>
										{ this.renderErrors('level') }

										<small className="form-text text-muted">
											Integer from 0 - 99. Higher value = higher level
										</small>
									</Colxx>
									<Colxx sm="6">
										<Label for="content" className="form-group has-float-label">
											<Input
												type="textarea"
												id="content"
												value={ message.get('content') || '' }
												disabled={ isLoading }
												onChange={ this.onChangeTextHandler }
												rows="7"
											/>
											<span>Content</span>
										</Label>
										{ this.renderErrors('content') }

										<FormGroup>
											<Label>Enable Member Remark</Label>
											<Switch
												className="custom-switch custom-switch-primary"
												checked={ message.get('enable_remark') }
												onChange={ this.onChangeEnableRemarkHandler }
											/>
										</FormGroup>
									</Colxx>
								</Row>

								<Separator className="mb-5" />

								<Row>
									<Colxx xxs="12">
										<FormGroup>
											<Label>Activate</Label>
											<Switch
												className="custom-switch custom-switch-primary"
												checked={ message.get('status') == 1 }
												onChange={ this.onChangeStatusHandler }
											/>
										</FormGroup>
									</Colxx>
								</Row>
							</CardBody>
						</Card>
					</Colxx>
				</Row>
			</Form>
		);
	}
}

FormMessageDetails.propTypes = {
	message: PropTypes.instanceOf(Map).isRequired,
	options: PropTypes.instanceOf(Map).isRequired,
	errors: PropTypes.object,
	isLoading: PropTypes.bool.isRequired,
	onChange: PropTypes.func.isRequired,
	onSubmit: PropTypes.func.isRequired,
};

export default FormMessageDetails;

