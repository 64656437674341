import React, { PureComponent, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { Row, Card, CardBody } from 'reactstrap';
import { Colxx, Separator } from 'Components/CustomBootstrap';
import IntlMessages from 'Util/IntlMessages';

class PageHome extends PureComponent {
	constructor() {
		super();
		this.items = [
			{
				id: 'menu.company',
				icon: 'iconsminds-building',
				href: '/company',
				permission: 'backend_company',
			},
			{
				id: 'menu.warehouse',
				icon: 'iconsminds-shop-3',
				href: '/warehouse',
				permission: 'backend_warehouse',
			},
			{
				id: 'menu.payment',
				icon: 'iconsminds-credit-card',
				href: '/payment',
				permission: 'backend_payment',
			},
			{
				id: 'menu.item-group',
				icon: 'iconsminds-glasses-3',
				href: '/item-group',
				permission: 'backend_item_group',
			},
			{
				id: 'menu.member-group',
				icon: 'iconsminds-gey',
				href: '/member-group',
				permission: 'backend_member_group',
			},
			// {
			// 	id: 'menu.promotion',
			// 	icon: 'iconsminds-checkout-basket',
			// 	href: '/promotion',
			// 	permission: 'backend_promotion',
			// },
			{
				id: 'menu.message',
				icon: 'simple-icon-bell',
				href: '/message',
				permission: 'backend_message',
			},
			{
				id: 'menu.transaction',
				icon: 'iconsminds-receipt-4',
				href: '/transaction',
				permission: 'backend_transaction',
			},
			{
				id: 'menu.user',
				icon: 'iconsminds-male-2',
				href: '/user',
				permission: 'backend_user',
			},
		];
	}

	render() {
		const { current } = this.props;
		const permissions = current.get('permissions');
		return (
			<Fragment>
				<Row>
					<Colxx xxs="12">
						<h1><IntlMessages id="home.heading" /></h1>
						<Separator className="mb-5" />

						<Row className="icon-cards-row mb-2">
							{
								this.items.map((item, index) => (
									!(permissions && permissions[item.permission] && permissions[item.permission] === ' ') && (
										<Colxx key={`page-home-button-${index}`} xxs="6" sm="4" md="3" lg="2">
											<NavLink to={`/app${item.href}`}>
												<Card className="mb-4">
													<CardBody className="text-center">
														<i className={item.icon} />
														<p className="card-text font-weight-semibold mb-0">
															<IntlMessages id={item.id} />
														</p>
													</CardBody>
												</Card>
											</NavLink>
										</Colxx>
									)
								))
							}
						</Row>
					</Colxx>
				</Row>
			</Fragment>
		);
	}
}

export default connect(
	(state) => ({
		current: state.users.current,
	})
)(PageHome);
