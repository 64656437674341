import {
	MEMBER_GROUPS_CREATE,
	MEMBER_GROUPS_CREATE_SUCCESS,
	MEMBER_GROUPS_CREATE_FAIL,
	MEMBER_GROUPS_UPDATE,
	MEMBER_GROUPS_UPDATE_SUCCESS,
	MEMBER_GROUPS_UPDATE_FAIL,
	MEMBER_GROUPS_FIND_ALL_BY_PAGE_AND_OPTIONS,
	MEMBER_GROUPS_FIND_ALL_BY_PAGE_AND_OPTIONS_SUCCESS,
	MEMBER_GROUPS_FIND_ALL_BY_PAGE_AND_OPTIONS_FAIL,
	MEMBER_GROUPS_GET_DETAILS,
	MEMBER_GROUPS_GET_DETAILS_SUCCESS,
	MEMBER_GROUPS_GET_DETAILS_FAIL,
	OPTIONS_GET,
} from 'Constants/actionTypes';

export const memberGroupsCreate = (data) => ({
	type: MEMBER_GROUPS_CREATE,
	payload: data,
});

export const memberGroupsCreateSuccess = (data) => {
	return ({
		type: MEMBER_GROUPS_CREATE_SUCCESS,
		payload: data,
	});
};

export const memberGroupsCreateFail = (error) => ({
	type: MEMBER_GROUPS_CREATE_FAIL,
	payload: error,
});

export const memberGroupsUpdate = (data) => ({
	type: MEMBER_GROUPS_UPDATE,
	payload: data,
});

export const memberGroupsUpdateSuccess = (data) => {
	return ({
		type: MEMBER_GROUPS_UPDATE_SUCCESS,
		payload: data,
	});
};

export const memberGroupsUpdateFail = (error) => ({
	type: MEMBER_GROUPS_UPDATE_FAIL,
	payload: error,
});

export const memberGroupsFindAllByPageAndOptions = (params) => ({
	type: MEMBER_GROUPS_FIND_ALL_BY_PAGE_AND_OPTIONS,
	payload: params,
});

export const memberGroupsFindAllByPageAndOptionsSuccess = (data) => {
	return ({
		type: MEMBER_GROUPS_FIND_ALL_BY_PAGE_AND_OPTIONS_SUCCESS,
		payload: data,
	});
};

export const memberGroupsFindAllByPageAndOptionsFail = (error) => ({
	type: MEMBER_GROUPS_FIND_ALL_BY_PAGE_AND_OPTIONS_FAIL,
	payload: error,
});

export const memberGroupsGetDetails = (id) => ({
	type: MEMBER_GROUPS_GET_DETAILS,
	payload: id,
});

export const memberGroupsGetDetailsSuccess = (data) => {
	return ({
		type: MEMBER_GROUPS_GET_DETAILS_SUCCESS,
		payload: data,
	});
};

export const memberGroupsGetDetailsFail = (error) => ({
	type: MEMBER_GROUPS_GET_DETAILS_FAIL,
	payload: error,
});

export const memberGroupsGetOptions = (params) => ({
	type: OPTIONS_GET,
	payload: {
		tag: 'memberGroup',
		params,
	},
});
