import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import PageMemberGroups from 'Pages/member-groups';
import PageMemberGroup from 'Pages/member-group';

const Home = ({ match }) => (
	<div className="dashboard-wrapper">
		<Switch>
			<Redirect exact from={`${match.url}/`} to={`${match.url}/list`} />
			<Route exact path={`${match.url}/list`} component={PageMemberGroups} />
			<Route exact path={`${match.url}/create`} component={PageMemberGroup} />
			<Route exact path={`${match.url}/edit/:memberGroupId`} component={PageMemberGroup} />
			<Redirect to="/error" />
		</Switch>
	</div>
);
export default Home;
