import React, { PureComponent, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { Row, Card, CardBody } from 'reactstrap';
import classnames from 'classnames';
import Pluralize from 'react-pluralize';
import ReactTable from 'react-table';
import { Colxx, Separator } from 'Components/CustomBootstrap';
import BreadcrumbContainer from 'Components/BreadcrumbContainer';
import DataTablePagination from 'Components/DataTables/pagination';
import {
	memberGroupsFindAllByPageAndOptions,
	errorReset,
} from 'Redux/actions';
import * as memberGroupsReducer from 'Redux/memberGroups/reducer';
import IntlMessages from 'Util/IntlMessages';

class PageMemberGroups extends PureComponent {
	constructor() {
		super();
		this.state = {
			keyword: '',
			tableState: {},
		};
		this.breadcrumb = [
			{ id: 'menu.member-group', href: '/member-group/list' },
		];
		this.columns = [
			{
				id: 'name',
				Header: 'Name',
				accessor: (d) => (
					<NavLink to={`/app/member-group/edit/${d.id}`}>
						{d.name}
					</NavLink>
				),
			},
			{
				Header: 'Description',
				accessor: 'description',
			},
			{
				id: 'numItems',
				Header: 'Item',
				sortable: false,
				accessor: (d) => (
					d.count !== null ? (
						<Pluralize singular="Item" count={d.count} />
					) : (
						'N/A'
					)
				),
			},
			{
				id: 'status',
				Header: 'Status',
				width: 100,
				accessor: (d) => {
					if (d.status) {
						let className = 'badge-primary';
						switch (d.status.label) {
						case 'Inactive':
							className = 'badge-light';
							break;
						}
						return (
							<span className={classnames('badge', 'badge-pill', 'text-uppercase', className)}>{d.status ? d.status.label : ''}</span>
						);
					}
					return '';
				},
			},
			{
				id: 'action',
				Header: '',
				width: 50,
				accessor: (d) => (
					<NavLink className="btn btn-xs btn-outline-primary" to={`/app/member-group/edit/${d.id}`}>
						<i className="simple-icon-pencil" />
					</NavLink>
				)
			},
		];
		this.fetchData = this.fetchData.bind(this);
		this.onChangeKeywordHandler = this.onChangeKeywordHandler.bind(this);
		this.onSubmitHandler = this.onSubmitHandler.bind(this);
	}

	componentDidMount() {
		this.props.errorReset('memberGroups');
	}

	fetchData(state) {
		const { keyword } = this.state;
		let params = {
			page: state.page + 1,
			size: state.pageSize,
		};
		if (state.sorted.length > 0) {
			params.sort_column = state.sorted[0].id;
			params.sort_direction = state.sorted[0].desc ? 'desc' : 'asc';
		}
		if (keyword) {
			params.keyword = keyword;
		}
		this.setState({
			tableState: state,
		});
		this.props.memberGroupsFindAllByPageAndOptions(params);
	}

	onChangeKeywordHandler(e) {
		this.setState({
			keyword: e.target.value,
		}, this.onSubmitHandler);
	}

	onSubmitHandler(e) {
		e && e.preventDefault();
		const { tableState } = this.state;
		this.fetchData(tableState);
	}

	render() {
		const { memberGroupsByPage } = this.props;
		const { keyword } = this.state;
		const status = this.props.status.get('memberGroups');
		const isLoading = status === 'fetching';
		return (
			<Fragment>
				<Row>
					<Colxx xxs="12">
						<div className="float-left">
							<BreadcrumbContainer
								heading={<IntlMessages id="member-groups.heading" />}
								items={this.breadcrumb}
								match={this.props.match}
							/>
						</div>
						<div className="float-right">
							<NavLink to="/app/member-group/create" className="btn btn-lg btn-primary">
								ADD GROUP
							</NavLink>
						</div>
						<div className="clearfix"></div>

						<form className="mb-3" onSubmit={this.onSubmitHandler}>
							<div className="search-sm d-inline-block mr-1 mb-1 align-top">
								<input
									type="text"
									placeholder="Search"
									value={keyword}
									onChange={this.onChangeKeywordHandler}
								/>
							</div>
						</form>
					</Colxx>
				</Row>

				<Separator className="mb-5" />

				<Row>
					<Colxx xxs="12">
						<Card>
							<CardBody>
								<ReactTable
									manual
									data={memberGroupsByPage.get('data')}
									pages={memberGroupsByPage.get('max_page')}
									loading={isLoading}
									onFetchData={this.fetchData}
									columns={this.columns}
									minRows={0}
									showPageJump={true}
									showPageSizeOptions={true}
									PaginationComponent={DataTablePagination}
								/>
							</CardBody>
						</Card>
					</Colxx>
				</Row>
			</Fragment>
		);
	}
}

export default connect(
	memberGroupsReducer.selector,
	{
		memberGroupsFindAllByPageAndOptions,
		errorReset,
	}
)(PageMemberGroups);
