import { all } from 'redux-saga/effects';
import authSagas from './auth/saga';
import optionsSagas from './options/saga';
import usersSagas from './users/saga';
import userRolesSagas from './userRoles/saga';
import companiesSagas from './companies/saga';
import configsSagas from './configs/saga';
import warehousesSagas from './warehouses/saga';
import warehouseGroupsSagas from './warehouseGroups/saga';
import paymentMethodsSagas from './paymentMethods/saga';
import itemGroupsSagas from './itemGroups/saga';
import memberGroupsSagas from './memberGroups/saga';
import promotionsSagas from './promotions/saga';
import messagesSagas from './messages/saga';
import transactionsSagas from './transactions/saga';
import maxBrandDiscountsSagas from './maxBrandDiscounts/saga';
import remarksSagas from './remarks/saga';
import purposesSagas from './purposes/saga';

export default function* rootSaga() {
	yield all([
		authSagas(),
		optionsSagas(),
		usersSagas(),
		userRolesSagas(),
		companiesSagas(),
		configsSagas(),
		warehousesSagas(),
		warehouseGroupsSagas(),
		paymentMethodsSagas(),
		itemGroupsSagas(),
		memberGroupsSagas(),
		promotionsSagas(),
		messagesSagas(),
		transactionsSagas(),
		maxBrandDiscountsSagas(),
		remarksSagas(),
		purposesSagas(),
	]);
}
