import React, { PureComponent, Fragment } from 'react';
import { Route, withRouter, Switch, Redirect } from 'react-router-dom';
import Axios from 'axios';
import TopNav from 'Containers/TopNav';
import Sidebar from 'Containers/Sidebar';
import ModalError from 'Containers/ModalError';

import home from './home';
import company from './company';
import warehouse from './warehouse';
import payment from './payment';
import itemGroup from './item-group';
import memberGroup from './member-group';
import promotion from './promotion';
import message from './message';
import transaction from './transaction';
import stockTransfer from './stock-transfer';
import user from './user';
import userRole from './user-role';
import config from './config';
import remark from './remark';

import { connect } from 'react-redux';
import { usersReadCurrent, refreshAccessToken } from 'Redux/actions';

class MainApp extends PureComponent {
	constructor() {
		super();
		this.state = {
			isRefreshing: false,
		};
		const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
		if (user) {
			// Set Axios default
			Axios.defaults.headers.common['Authorization'] = `Bearer ${user.access_token}`;
			Axios.defaults.headers.post['Content-Type'] = 'application/json';
		}
		this.refreshAccessToken = this.refreshAccessToken.bind(this);
	}

	componentDidMount() {
		this.props.usersReadCurrent();
		this.refreshAccessToken();
		this.interval = setInterval(this.refreshAccessToken, 30000); // Check every 30 seconds
	}

	componentWillUnmount() {
		clearInterval(this.interval);
	}

	refreshAccessToken() {
		const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
		const timestamp = (new Date()).getTime() / 1000;
		if (user) {
			if (timestamp - user.timestamp > user.expires_in - 60) {
				// Expiring in 1 minute
				const { isRefreshing } = this.state;
				if (!isRefreshing) {
					this.setState({
						isRefreshing: true,
					});
					this.props.refreshAccessToken(user.user_id);
				}
			} else {
				const { isRefreshing } = this.state;
				if (isRefreshing) {
					this.setState({
						isRefreshing: false,
					});
					location.reload();

					// Set Axios default
					Axios.defaults.headers.common['Authorization'] = `Bearer ${user.access_token}`;
					Axios.defaults.headers.post['Content-Type'] = 'application/json';
				}
			}
		}
	}

	render() {
		const { match, containerClassnames, history } = this.props;
		return (
			<Fragment>
				<div id="app-container" className={ containerClassnames }>
					<TopNav history={ history } />
					<Sidebar match={ match } />
					<main>
						<div className="container-fluid">
							<Switch>
								<Route path={ `${match.url}/home` } component={ home } />
								<Route path={ `${match.url}/company` } component={ company } />
								<Route path={ `${match.url}/warehouse` } component={ warehouse } />
								<Route path={ `${match.url}/payment` } component={ payment } />
								<Route path={ `${match.url}/item-group` } component={ itemGroup } />
								<Route path={ `${match.url}/member-group` } component={ memberGroup } />
								<Route path={ `${match.url}/promotion` } component={ promotion } />
								<Route path={ `${match.url}/message` } component={ message } />
								<Route path={ `${match.url}/transaction` } component={ transaction } />
								<Route path={ `${match.url}/stock-transfer` } component={ stockTransfer } />
								<Route path={ `${match.url}/user` } component={ user } />
								<Route path={ `${match.url}/user-role` } component={ userRole } />
								<Route path={ `${match.url}/config` } component={ config } />
								<Route path={ `${match.url}/remark` } component={ remark } />
								<Redirect to="/error" />
							</Switch>

							<footer className="my-4 text-muted text-center">
								&copy; 2022-{ new Date().getFullYear() } Europe Watch Company
							</footer>
						</div>
					</main>
				</div>
				<ModalError history={ history } />
			</Fragment>
		);
	}
}
const mapStateToProps = ({ menu }) => {
	const { containerClassnames } = menu;
	return { containerClassnames };
};

export default withRouter(
	connect(
		mapStateToProps,
		{
			usersReadCurrent,
			refreshAccessToken,
		}
	)(MainApp)
);
